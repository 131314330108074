import React from 'react'
import { images } from 'config/images'

export const WebAppDevSerProps = {
  ppcbannerProps: {
    heading: (
      <>
        Web application development services to build the{' '}
        <span className="highlight-head">next generation of apps</span>
      </>
    ),
    para: (
      <>
        Modern web applications to{' '}
        <span className="color-through">solve your biggest challenges.</span>{' '}
        Deliver experiences that have the look and feel of native apps
      </>
    ),
    formCTA: 'Speak with tech expert',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Leading companies <span className="highlight-head">trust us</span> to
        build applications
      </>
    ),
    buildAppPara: (
      <>
        Our web app engineering team has delivered products to{' '}
        <span className="color-through">over 400 clients</span>, including
        global brands such as Google, Pepsi, Hilton, Redbull, and Sony Music.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our areas of expertise in
        <br />
        <span className="highlight-head">Web Application</span> Development
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'UX design and Prototyping',
        serviceParagraph: (
          <>
            We blend <strong>intuitive and efficient design</strong> with
            behavioral patterns to create delightful user experience. Powerful
            UI and UX enables higher user adoption, easier on-boarding and
            ultimately more sales.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Broad Range of Tech Skills',
        serviceParagraph: (
          <>
            Our engineers have a deep understanding of the{' '}
            <strong>latest frameworks</strong> for frontend, backend, and
            databases. This enables us to build the architecture and tech stack
            for your future-ready web app.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'API Development and Integration',
        serviceParagraph: (
          <>
            <strong>create APIs for mobile</strong>, cloud apps, web sockets,
            browsers and databases to augment the web application which
            facilitates access to application data, features and business logic.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'Manual',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Re-engineering and Performance Tuning',
        serviceParagraph: (
          <>
            Growing businesses face scalability and performance issues. We
            become <strong>your extended team</strong> to improve the
            architecture, refactor old code, improve deployment and API
            integrations.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Software',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Quality Assurance and Software Testing',
        serviceParagraph: (
          <>
            We don’t just test functionality or match developed features with
            your requirements, <strong>we test to ensure</strong> that users
            will be able to use the application as intended.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'DevOps',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Continuous Integration and Deployment',
        serviceParagraph: (
          <>
            Our DevOps team will work with your team to establish detailed
            <strong> deployment objectives and timelines</strong>, covering
            configuration, testing, governance, troubleshooting and more.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Performance',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with <br />
        Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Navigate web complexities with experienced consultants',
        workingBenefitsContent: (
          <>
            There are a lot of firms that can build web apps. But there are few
            who can develop <strong>world-class web apps</strong> that will
            drive results. Our team coaches you through every tech decision
            critical to you.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Structured architectural patterns for developing systems at scale',
        workingBenefitsContent: (
          <>
            Build <strong>large scale web applications</strong> adhering to
            Google’s definition of “progressiveness”, making them fast even on
            2G networks, offline-available, and easily installable across
            devices.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Great web apps start with great front-end',
        workingBenefitsContent: (
          <>
            Slow load times, poor performance, and dated UX design can impact
            your bottom line. Your business depends on providing{' '}
            <strong>differentiated and engaging</strong> experiences.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Case studies on how{' '}
        <span className="highlight-head">web applications</span> helped these
        companies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">customers say</span> about our web
        applications development services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/building-scalable-application-aws-platform/',
        featurecardTitle:
          'How to Build a Scalable Application up to 1 Million Users on AWS',
      },
      {
        blogLink: 'https://www.simform.com/how-to-develop-ecommerce-apps/',
        featurecardTitle: 'How to Make an eCommerce App',
      },
      {
        blogLink: 'https://www.simform.com/react-vs-vue/',
        featurecardTitle:
          'React vs Vue – The CTOs guide to Choosing the Right Framework',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactJignesh,
    personImg2x: images.contactJignesh2x,
    personName: 'Jignesh Solanki',
    personDesignation:
      'Employing a consultative approach and building a team of tech experts to deliver the best solution.',
    personEmail: 'jignesh@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
